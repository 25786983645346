import './index.scss'

$('[data-open="cmenu"]').on('click', function () {
  if($('header .hamburger').hasClass('active')){
    $('header .hamburger').removeClass('active')
    $('header .mob-header').removeClass('show')
    $('.header').removeClass('show-menu')
    $('header .menu-mask').fadeOut(200)
    $('body').css({
      'height':'auto',
      'overflow-y':'auto'
    })
  }else{
    $('header .hamburger').addClass('active')
    $('header .mob-header').addClass('show')
    $('.header').addClass('show-menu')
    $('header .menu-mask').fadeIn(200)
    $('body').css({
      'height':'100vh',
      'overflow-y':'hidden'
    })
  }
})

$('.mob-header ul li .sub-nav a').on('click',function(){
  setTimeout(function(){
    $('header .hamburger').removeClass('active')
    $('header .mob-header').removeClass('show')
    $('header .menu-mask').fadeOut(200)
    $('body').css({
      'height':'auto',
      'overflow-y':'auto'
    })
  },200)
})

$('.mob-header ul li .title .iconfont').on('click',function(){
  let $parentEl = $(this).parents('li')
  if($parentEl.hasClass('active')){
    $parentEl.removeClass('active')
    $parentEl.find('.sub-nav').slideUp()
  }else{
    $parentEl.addClass('active').siblings().removeClass('active')
    $parentEl.find('.sub-nav').slideDown()
    $parentEl.siblings().find('.sub-nav').slideUp()
  }
})

var mouseover_tid = [];
var mouseout_tid = [];
$(document).ready(function () {
  $('.mob-header ul li').each(function(index){
    if($(this).find('.sub-nav a').length > 0){
      $(this).find('.title .iconfont').css('display','flex')
    }
  })
  $(".header .right .nav ul li").each(function (index) {
    $(this).hover(
      // 鼠标进入
      function () {
        var _self = this;
        // 停止卷起事件
        clearTimeout(mouseout_tid[ index ]);
        // 当鼠标进入超过 0.2 秒, 展开菜单, 并记录到线程 ID 中
        mouseover_tid[ index ] = setTimeout(function () {
          $(_self).addClass('hover');
          let headerH = $(".header").outerHeight()
          if($(_self).find('.sub-nav').length > 0){
            let subNavH = $(_self).find('.sub-nav').outerHeight()
            console.log(headerH+subNavH)
            $(".header .bg").css('height', headerH + subNavH +'px');
          }else{
            if($(".header").hasClass('scroll')){
              $(".header .bg").css('height', '100%');
            }else{
              $(".header .bg").css('height', '0px');
            }
          }
        }, 201);
      },
      // 鼠标离开
      function () {
        var _self = this;
        // 停止展开事件
        clearTimeout(mouseover_tid[ index ]);
        // 当鼠标离开超过 0.2 秒, 卷起菜单, 并记录到线程 ID 中
        mouseout_tid[ index ] = setTimeout(function () {
          $(_self).removeClass('hover');
          let headerH = $(".header").outerHeight()
          if($(".header").hasClass('scroll')){
            $(".header .bg").css('height', '100%');
          }else{
            $(".header .bg").css('height', '0px');
          }
        }, 201);
      });
    });
});

$(document).scroll(function () {
  var scroH = $(document).scrollTop(); //滚动高度
  var viewH = $(window).height(); //可见高度
  var contentH = $(document).height(); //内容高度

  if($('.header .right .nav ul li.hover').length == 0){
    if(scroH > 100){
      $('.header').addClass('scroll').find('.bg').css('height', '100%')
    }else{
      $('.header').removeClass('scroll').find('.bg').css('height', '0px')
    }
  }
  
  if($('.nybanner').length > 0){
    $('.nybanner .bg-img').css('transform','translateY('+ scroH / 3 +'px)')
  }
});