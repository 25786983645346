
// import {WOW} from '../../../node_modules/wowjs/dist/wow';
// new WOW().init();
import AOS from 'aos';               
import '../styles/aos.css';

$(document).ready(function(){
  setTimeout(function(){
    AOS.init({
      disable: false,
      duration: 600,
      offset: 30,
      easing: 'ease-in-out-sine'
    });
  },200)
})