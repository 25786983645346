module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!bannerPath) var bannerPath='';
if (!title) var title = '';
;
__p += '\r\n<section class="nybanner">\r\n  <div class="line container"></div>\r\n  <div class="bg-img bg-cover" style="background-image: url(' +
((__t = ( bannerPath )) == null ? '' : __t) +
');"></div>\r\n  <div class="t bold container">\r\n    <div><h1 class="shuffle">' +
((__t = ( title )) == null ? '' : __t) +
'</h1></div>\r\n  </div>\r\n</section>';

}
return __p
}